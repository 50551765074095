// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-custom-made-systems-js": () => import("./../../../src/pages/custom-made-systems.js" /* webpackChunkName: "component---src-pages-custom-made-systems-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mobile-native-applications-js": () => import("./../../../src/pages/mobile-native-applications.js" /* webpackChunkName: "component---src-pages-mobile-native-applications-js" */),
  "component---src-pages-projects-js": () => import("./../../../src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-website-development-js": () => import("./../../../src/pages/website-development.js" /* webpackChunkName: "component---src-pages-website-development-js" */),
  "component---src-templates-project-inner-js": () => import("./../../../src/templates/project-inner.js" /* webpackChunkName: "component---src-templates-project-inner-js" */)
}

